<template lang="pug">
v-container.fullheight
  v-card.pa-0.ma-0
    v-card-title Your Lightning Points
      v-spacer
      v-btn.accent(icon, @click="save")
        v-icon mdi-content-save-settings
    v-expansion-panels(v-model="mainPanels")
      v-expansion-panel.mt-2
        v-expansion-panel-header.primary 
          div
            v-icon.pr-4 mdi-account-supervisor-circle
            span {{ campaign.name ? campaign.name : 'Check-in locations' }}
        v-expansion-panel-content
          h4.mt-4 Cover image
          v-image-input(
            v-model="headerImage",
            :image-quality="1.0",
            clearable,
            image-format="jpeg",
            :imageWidth="780",
            :imageHeight="250"
            uploadIcon="mdi-camera"
            @input="coverImageInput"
            overlayPadding="0px"

          )
          h4 Business logo
          v-image-input(
            v-model="logoImage",
            :image-quality="1.0",
            clearable,
            image-format="jpeg",
            style="margin-left: 50%; transform: translate(-50%)"
            uploadIcon="mdi-camera"
            @input="logoImageInput"
            overlayPadding="0px"
          )
          v-form
            v-text-field(
              v-model="campaign.name",
              label="Your business or sponsorship name"
            )
            v-textarea.mb-10(
              v-model="campaign.description",
              label="Extra information (optional)",
              hint="This is what conscious commuters read first when they check out your campaign."
            )
            v-row.mb-10.mx-0
              h4 Target Locations
              v-expansion-panels.mt-4(v-model="openedLocation", accordion)
                LocationCard(
                  v-for="l in campaign.locations",
                  :key="l.id",
                  :location="l",
                  :actions="campaign.actions",
                  @remove="deleteLocation"
                )
              v-btn.primary.my-5(@click="addLocation")
                v-icon mdi-map-marker-plus
                span Add Location

            span Terms
            tiptap-vuetify(
              v-model="campaign.terms",
              :extensions="extensions",
              :toolbar-attributes="{ color: 'black', dark: true }",
              placeholder="Add your terms for this promotion..."
            )
      v-expansion-panel.mt-2
        v-expansion-panel-header.info
          div
            v-icon.pr-4 mdi-gesture-tap
            span Check-in rewards
        v-expansion-panel-content
          v-expansion-panels.mt-4.pa(v-model="openedAction", accordion)
            ActionCard(
              v-for="a in campaign.actions",
              :key="a.id",
              :action="a",
              @remove="deleteAction"
            )
          v-btn.primary.my-5(fab, @click="addAction")
            v-icon mdi-gesture-tap
      v-expansion-panel.mt-2
        v-expansion-panel-header.info
          div
            v-icon.pr-4 mdi-credit-card-settings-outline
            span Limits, balance and payment details
        v-expansion-panel-content
          v-row
            v-col
              v-text-field(
                type="number",
                readonly,
                disabled,
                v-model="campaign.balance",
                label="Account balance"
              )
            v-col
              v-btn.mt-4.primary(block, small, @click="topup") Top up
          v-checkbox(
            v-model="campaign.autoTopup",
            label="Automatically top up when your balance is below $10"
          )
          v-currency-field(
            label="Set a monthly spend limit",
            v-model="maxSpend",
            hint="Lightning Points are pre-pay and roll-over month on month. Your check-in locations will disappear when your balance is 0.",
            :rules="spendRangeRules",
            :suffix="spendSuffix",
            prefix="$"
          )
          div(v-if="user && user.cardLastDigits && niceExpiry")
            .text-h6 Card on file
            v-text-field.pre(
              :value="'**** **** **** ' + user.cardLastDigits",
              label="Card Number",
              filled,
              readonly
            )
            v-text-field.pre(
              :value="niceExpiry",
              label="Expiry",
              filled,
              readonly
            )
          v-dialog(
            v-model="paymentDialog",
            max-width="640",
            :persistent="!clickToClose"
          )
            template(v-slot:activator="{ on }")
              v-btn.mt-6(v-on="on", color="primary", small, block)
                v-icon mdi-credit-card-settings
                span {{ user.cardLastDigits ? 'Change Payment Card' : 'Add Credit Card' }}
            v-card
              v-card-title Payment Details
              v-card-text
                p Please provide a card on file to cover your points that put on the map.
                v-stripe-card(
                  v-model="source",
                  ref="stripeCard",
                  @focus="onFocus",
                  @input="onProcessed",
                  api-key="pk_test_51Hcf7jCocilWKKqgHSTpLHZMmO2oN0A1xPwwHIpuBaljKXJq0wlCethPuboAPydyoFgJF1G6JeyeRTJwp4v94Vab00b6YlqMfc",
                  :hidePostalCode="true",
                  create="source"
                )
                p 
                  | I agree to the &nbsp;
                  a(
                    href="https://powertrip.earth/power-trip-dealer-customer-terms-and-conditions.pdf"
                  ) Power Trip terms and conditions
                  |
                  | and authorise PowerTrip to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.
                p 
                  | For more information contact &nbsp;
                  a(href="mailto:info@powertrip.earth") info@powertrip.earth
                  |
                  | or call 022 6495517

      v-expansion-panel.mt-2
        v-expansion-panel-header.accent
          div
            v-icon.pr-4 mdi-map-marker-circle
            span Your business profile
        v-expansion-panel-content
          POICard
      v-expansion-panel.mt-2
        v-expansion-panel-header.accent 
          div
            v-icon.pr-4 mdi-ev-station
            span Your charging stations
        v-expansion-panel-content
          v-expansion-panels
            ChargerCard(v-for="c in chargers", :key="c.id", :charger="c")
          v-btn.primary.my-5(fab, @click="addCharger")
            v-icon mdi-ev-station
    v-snackbar(v-model="snackbar", :timeout="timeout") 
      v-row(align="center")
        v-col(cols=8) {{ text }}
        v-col(cols=2)
          v-btn.secondary(icon, @click="dialog = true")
            v-icon mdi-undo
        v-col(cols=2)
          v-btn.accent(icon, @click="save")
            v-icon mdi-content-save-settings
    v-dialog(v-model="dialog", max-width="360")
      v-card
        v-card-title.headline Confirm undo?
        v-card-text You will lose all changes since the last save.
        v-card-actions
          v-spacer
          v-btn(text, @click="dialog = false") Cancel
          v-btn.primary(text, @click="undo") Confirm
    v-dialog(v-model="topupDialog", max-width="360")
      v-card
        v-card-title.headline Topup Points
        v-card-text
          v-currency-field(
            label="How much do you want to top up?",
            v-model="topupAmount",
            hint="Your payment will be converted into lightning points and added to your points balance.",
            :rules="spendRangeRules",
            :suffix="topUpSpendSuffix",
            prefix="$"
          )
        v-card-actions
          v-spacer
          v-btn(text, @click="topupDialog = false") Cancel
          v-btn.primary(text, @click="makePayment") Confirm
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import LocationCard from "./LocationCard";
import ActionCard from "./ActionCard";
import PowerTrip from "../services/api/PowerTrip";
import VImageInput from "vuetify-image-input/a-la-carte";
import _ from "lodash";
import { mapBus, bus } from "../main";

import POICard from "./POICard";
import ChargerCard from "./ChargerCard";
export default {
  props: ["campaign", "user","chargers"],
  components: {
    TiptapVuetify,
    LocationCard,
    ActionCard,
    POICard,
    ChargerCard,
    VImageInput,
  },
  data() {
    return {
      snackbar: false,
      dialog: false,
      headerUpload: false,
      logoUpload: false,
      timeout: -1,
      headerImage: null,
      logoImage: null,
      processing: false,
      saveHeader: false,
      saveLogo: false,
      text: "Campaign Saved",
      openedAction: 0,
      openedLocation: 0,
      mainPanels: null,
      clickToClose: true,
      paymentDialog: false,
      topupDialog: false,
      topupAmount: 0,
      settings: {},
      source: null,
      maxSpend: this.campaign.maxPoints * 0.3,
      stripeCardInitialized: false,
      oldCampaign: JSON.parse(JSON.stringify(this.campaign)),
      spendRangeRules: [
        (v) => !!v || "You need to specify a minimum spend",
        (v) =>
          (v &&
            (typeof v === "string" ? parseFloat(v.replace(/,/g, "")) : v) >=
              parseFloat(this.settings.TopUpAmount)) ||
          "Your minimum spend must be at least $" + this.settings.TopUpAmount,
      ],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    };
  },

  created() {
    mapBus.$on("add", (item) => {
      this.campaign.locations.push(item);
      this.openedLocation = this.campaign.locations.length - 1;
      this.mainPanels = 1;
    });
    mapBus.$on("addCharging",(item) => {
      this.chargers.push({Address:{Address: item.display_name},Current:"DC",KwRated:50,Title:item.display_name,Latitude: item.lat, Longitude: item.lon});
      this.mainPanels=4;
    });
    mapBus.$on("updateCharger",() => {
      this.mainPanels=4;
    })
    this.headerImage = this.campaign.headerUrl;
    this.logoImage = this.campaign.logoUrl;
    PowerTrip.getChargers().then((r) => (this.chargers.splice(0,this.chargers.length,...r)));
    PowerTrip.getSettings().then((s) => (this.settings = s));
    bus.$on("dirty", () => {
      this.hasUnsaved(true);
    });
  },
  computed: {
    spendSuffix() {
      if (this.maxSpend >= this.settings.TopUpAmount) {
        var points = this.maxSpend / (this.settings.PointRetailCost / 100.0);
        var minTrees = (points / 250).toFixed(1);
        var maxTrees = Math.round(minTrees * 3);
        return (
          "(" +
          Math.ceil(points) +
          " points + up to " +
          maxTrees +
          " tree" +
          (maxTrees == 1 ? ")" : "s)")
        );
      }
      return "";
    },
    topUpSpendSuffix() {
      if (this.topupAmount >= this.settings.TopUpAmount) {
        var points = this.topupAmount / (this.settings.PointRetailCost / 100.0);
        var minTrees = (points / 250).toFixed(1);
        var maxTrees = Math.round(minTrees * 3);
        return (
          "(" +
          Math.ceil(points) +
          " points + up to " +
          maxTrees +
          " tree" +
          (maxTrees == 1 ? ")" : "s)")
        );
      }
      return "";
    },
    niceExpiry() {
      if (this.user.cardExpiry) {
        let components = this.user.cardExpiry.split("-");
        if (components.length == 3) {
          return components[1] + "/" + components[0];
        }
      }
      return false;
    },
  },
  methods: {
    coverImageInput() {
      this.saveHeader = true;
    },
    logoImageInput() {
      this.saveLogo = true;
    },
    addCharger() {
      this.chargers.push({Address:{ Address: "" },Current:"DC",KwRated:50});
    },
    topup() {
      this.topupDialog = true;
      //await PowerTrip.topUp();
    },
    async makePayment() {
      await PowerTrip.topUp(this.topupAmount);
      this.$emit("campaignUpdated");
      this.topupDialog = false;
    },
    onFocus: function () {
      if (!this.stripeCardInitialized) {
        this.stripeCardInitialized = true;
        this.$watch(
          () => this.$refs.stripeCard.okToSubmit,
          (value) => {
            console.log(value);
            this.$refs.stripeCard.processCard();
          }
        );
      }
    },
    onProcessed: function () {
      if (!this.processing) {
        this.processing = true;
        this.user.cardLastDigits = this.source.card.last4;
        this.user.cardExpiry =
          this.source.card.exp_year + "-" + this.source.card.exp_month + "-01";
        // this.user.stripeSecret = this.source.client_secret;
        this.user.stripePaymentMethod = this.source.type;
        this.user.stripeSecret = this.source.id;
        this.paymentDialog = false;
        this.clickToClose = true;
        PowerTrip.saveUserData(this.user).then(() => {
          this.$emit("userUpdated");
          this.processing = false;
        });
      }
    },
    async save() {
      let panel = this.mainPanels;
      this.mainPanels = 4;
      this.$nextTick(() => {
      if (this.headerImage && this.saveHeader) {
        this.campaign.header = this.headerImage;
        this.campaign.headerExt = "jpg";
      }
      if (this.logoImage && this.saveLogo) {
        this.campaign.logo = this.logoImage;
        this.campaign.logoExt = "jpg";
      }

      this.campaign.maxSpend = this.maxSpend * 100;
      bus.$emit("save");
      PowerTrip.saveCampaign(this.campaign).then(() => {      
      this.oldCampaign = JSON.parse(JSON.stringify(this.campaign));
      this.text = "Campaign Saved";
      this.timeout = 2000;
      this.snackbar = true;
      PowerTrip.getChargers().then((r) => (this.chargers.splice(0,this.chargers.length,...r)));
      this.mainPanels = panel;
       });
      });
    },
    undo() {
      this.$emit("undo", this.oldCampaign);
      bus.$emit("undo");
      this.headerImage = this.campaign.headerUrl;
      this.logoImage = this.campaign.logoUrl;
      this.dialog = false;
    },
    addLocation() {
      this.campaign.locations.push({});
      this.openedLocation = this.campaign.locations.length - 1;
      this.mainPanels = 0;
    },

    addAction() {
      var action = {};
      action.enabled = true;
      action.locationRequired = true;

      this.campaign.actions.push(action);
      this.openedAction = this.campaign.actions.length - 1;
      this.mainPanels = 3;
    },
    deleteLocation(location) {
      this.campaign.locations.splice(
        0,
        this.campaign.locations.length,
        ...this.campaign.locations.filter((e) => e.id !== location.id)
      );
    },
    deleteAction(action) {
      this.campaign.actions.splice(
        0,
        this.campaign.actions.length,
        ...this.campaign.actions.filter((e) => e.id !== action.id)
      );
    },
    hasUnsaved(t) {
      if (t) {
        this.snackbar = true;
        this.text = "You have unsaved changes";
        this.timeout = -1;
      } else {
        this.snackbar = false;
      }
    },
  },
  watch: {
    campaign: {
      deep: true,
      handler() {
        if (
          !_.isEqual(this.campaign, this.oldCampaign) ||
          this.headerImage ||
          this.logoImage
        ) {
          this.hasUnsaved(true);
        } else {
          this.hasUnsaved(false);
        }

        if (!this.campaign.terms || this.campaign.terms == "<p></p>") {
          // this.$nextTick(() => {
          this.campaign.terms =
            "<a href='https://powertrip.earth/terms-and-conditions' target='_blank'>Standard Terms and Conditions</a>";
          // });
        }
      },
    },
    chargers: {
      deep: true,
      handler() {
        this.eventHub.$emit("update", this.chargers);
      },
    },
  },
};
</script>
<style scoped lang="scss">
.fullheight {
  max-height: calc(100vh - 154px);
  min-height: calc(100vh - 154px);
  overflow-y: scroll;
  width: 100%;
}
</style>