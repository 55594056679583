<template lang="pug">
v-expansion-panel
  v-expansion-panel-header {{ location.name || 'Enter Location'}}
  v-expansion-panel-content
    v-form
      v-text-field(
        v-model="location.name",
        label="Name",
        hint="What is at this target location?"
      )
      AddressFinder.mb-5(:model="address", :clear="true", label="Address")
      v-text-field(
        v-model="location.geoFenceRange",
        type="number",
        label="Check-in distance",
        suffix="metres",
        :rules="rangeRules",
        hint="How close do people need to be to claim points?"
      )
      v-select(
        :items="actionList",
        label="Reward Actions",
        item-text="title"
        multiple,
        v-model="location.actions"
      )
      v-expansion-panels
        v-expansion-panel
          v-expansion-panel-header Opening Hours
          v-expansion-panel-content
            Hours(v-for="hour in location.hours", :key="hour.dayOfWeek", :hour="hour")
      v-card-actions
        v-spacer
        v-btn.red(icon, @click.stop="dialog = true") 
          v-icon mdi-delete
    v-dialog(v-model="dialog", max-width="360")
      v-card
        v-card-title.headline Confirm deleting location?
        v-card-text The location will be removed from the map. This cannot be undone.
        v-card-actions
          v-spacer
          v-btn(text, @click="dialog = false") Cancel
          v-btn.primary(text, @click="deleteMe") Delete
</template>
<script>
import AddressFinder from "./AddressFinder.vue";
import Hours from "./Hours.vue";
export default {
  props: ["location","actions"],
  components: { AddressFinder, Hours },
  data() {
    return {
      dialog: false,
      address: { Address: this.location.address },
      actionList: this.actions.filter(a => a.title),
      rangeRules: [
        (v) => !!v || "You need to specify a range for this location",
        (v) => (v && v <= 200) || "Range should be no more than 200m",
        (v) => (v && v >= 5) || "Range should be at least 5m",
      ],
    };
  },
  mounted() {
    let days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    days.forEach((item) => {
      let d = this.location.hours.find((e) => e.dayOfWeek == item);
      if (!d) {
        this.location.hours.push({ dayOfWeek: item, open24: true });
      }
    });
    this.location.hours.sort((a, b) => {
      if (days.indexOf(a.dayOfWeek) > days.indexOf(b.dayOfWeek)) {
        return 1;
      }
      return -1;
    });
  },
  methods: {
    deleteMe() {
      this.dialog = false;
      this.$emit("remove", this.location);
    },
  },
  watch: {
    address: {
      deep: true,
      handler() {
        this.location.address = this.address.Address.Address;
        this.location.lat = this.address.Address.geometry.coordinates[1];
        this.location.lng = this.address.Address.geometry.coordinates[0];
      },
    },
    actions: {
      deep: true,
      handler() {
        this.actionList = this.actions.filter(a => a.title);
      }
    }
  },
};
</script>