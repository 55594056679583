<template lang="pug">
v-form
  h4.mt-2 Cover Image
  v-image-input(
        v-model="image",
        :image-quality="1.0",
        clearable
        image-format="jpeg"
        :imageWidth="660",
        :imageHeight="250"
        uploadIcon="mdi-camera"
        @input="imageInput"
        overlayPadding="0px"
      )
  v-text-field(label="Name", v-model="poi.Title")
  AddressFinder.mb-5(:model="address", :clear="true", label="Address") 
  v-text-field(label="Your Website", v-model="poi.Website" hint="Add a link to your booking platform or your business' website here" persistent-hint)
  v-textarea(label="Your story or description", v-model="poi.Description")
  v-textarea(label="Climate or social action you are taking", v-model="poi.WhyUs")
  v-checkbox(label="Share with Power Trip users", v-model="poi.Public")
</template>

<script>
import AddressFinder from "./AddressFinder.vue";
import PowerTrip from "../services/api/PowerTrip.js";
import { bus, mapBus } from "../main";
import _ from "lodash";
import VImageInput from 'vuetify-image-input/a-la-carte';
export default {
  props: ["location"],
  components: { AddressFinder, VImageInput },
  data() {
    return {
      address: { Address: "" },
      image: null,
      saveImage: false,
      poi: {},
      oldPoi: {},
    };
  },
  created() {
    PowerTrip.getPoi().then((r) => {
      this.poi = r;
      this.address = {Address:{Address:r.Address,geometry:{coordinates:[r.Longitude,r.Latitude]}}}
      this.image = this.poi.PhotoData;
      this.oldPoi = JSON.parse(JSON.stringify(this.poi));
    });
    bus.$on("save", () => {
      this.save();
    });
    bus.$on("undo", () => {
      this.undo();
    });
    mapBus.$on("move", (address) => {
      let add = {Address:{Address:address.display_name,geometry:{coordinates:[address.lon,address.lat]}}}
      
      this.address = add;
    })
  },
  methods: {
    imageInput(){
      this.saveImage = true;
    },
    undo() {
      this.poi = this.oldPoi;
    },
    save() {
      if (this.saveImage) {
        this.poi.PhotoData = this.image;
      }
      PowerTrip.newPoi(this.poi);
      this.oldPoi = this.poi;
    },
  },
  watch: {
    address: {
      deep: true,
      handler() {
        this.poi.Address = this.address.Address.Address;
        this.poi.Latitude = this.address.Address.geometry.coordinates[1];
        this.poi.Longitude = this.address.Address.geometry.coordinates[0];
      },
    },
    poi: {
      deep: true,
  handler() {
    if (!_.isEqual(this.poi, this.oldPoi)) {
      bus.$emit("dirty")
      bus.$emit("poi",this.poi);
    }
  }
    },
  },
};
</script>