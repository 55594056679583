<template lang="pug">
v-expansion-panel
  v-expansion-panel-header {{ action.title || "New Reward" }}
  v-expansion-panel-content
    v-form(ref="form" lazy-validation)
      h4 How can customers check-in at your locations?
      //-v-checkbox(v-model="action.locationRequired", label="Visit a check-in location" persistent-hint hint="(determined by the customer's phone GPS)")
      v-checkbox(v-model="action.questionRequired", label="Answer a question privately" persistent-hint hint="(eg. doing market research or giving you feedback)")
      v-text-field(
        v-if="action.questionRequired",
        v-model="action.questionTitle",
        label="What is your question?"
      )
      v-text-field(
        v-if="action.questionRequired",
        v-model="action.questionText",
        label="More Details",
        hint="Add some more information or details to help your customers answer your question."
      )
      v-checkbox(
        v-model="action.photoUploadRequired",
        label="Create public content"
        persistent-hint
        hint="(eg. sharing a story or leaving a review)"
      )
      v-text-field(
        v-model="action.title",
        label="Give this action a name",
        hint="This is what people read first when they collect Lightning Points from you"
      )
      v-text-field(
        v-model="action.description",
        label="Add some instructions (optional)",
        hint="Add more information about what the customer has to do when they are here."
      )
      v-text-field(
        v-model="action.points",
        type="number",
        label="How many points does each check-in earn?",
        :rules="pointsRules"
      )
      v-row
        v-col(cols=8)
          v-text-field(
            type="number",
            v-model="cooldown.time",
            label="Cooldown Time",
            hint="How long until someone can claim Lightning Points at this location again?"
          )
        v-col(cols=4)
          v-select(:items="durations", v-model="cooldown.duration")
      v-text-field(
        v-if="action.locationRequired",
        type="number",
        v-model="action.locationWaitTime",
        label="How long do they need to be on site?",
        hint="Minutes that a customer has to spend at your target locations before they can use this action",
        suffix="minutes"
      )
      v-card-actions
        v-spacer
        v-btn.red(icon, @click.stop="dialog = true") 
          v-icon mdi-delete
    v-dialog(v-model="dialog", max-width="360")
      v-card
        v-card-title.headline Confirm deleting action?
        v-card-text The action will be removed from the campaign. This cannot be undone.
        v-card-actions
          v-spacer
          v-btn(text, @click="dialog = false") Cancel
          v-btn.primary(text, @click="deleteMe") Delete
</template>
<script>
export default {
  props: ["action"],
  data() {
    return {
      dialog: false,
      pointsRules: [
        (v) => !!v || "You must enter at least 1 point.",
        (v) => (v && v >= 1) || "You must enter at least 1 point.",
        (v) => (v && v <= 20) || "Are you sure?",
      ],
      durations: ["minutes", "hours", "days"],
      cooldown: {},
    };
  },
  mounted() {
    if (this.action.cooldownTime < 60) {
      this.cooldown = { duration: "minutes", time: this.action.cooldownTime };
      return;
    }
    if (this.action.cooldownTime < 1440) {
      this.cooldown = {
        duration: "hours",
        time: this.action.cooldownTime / 60,
      };
      return;
    }
    this.cooldown = { duration: "days", time: this.action.cooldownTime / 1440 };
  },

  methods: {
    deleteMe() {
      this.dialog = false;
      this.$emit("remove", this.action);
    },
  },
  watch: {
    cooldown: {
      deep: true,
      handler: function (newVal) {
        switch (newVal.duration) {
          case "minutes":
            this.action.cooldownTime = newVal.time;
            break;
          case "hours":
            this.action.cooldownTime = newVal.time * 60;
            break;
          case "days":
            this.action.cooldownTime = newVal.time * 1440;
            break;
        }
      },
    },
  },
};
</script>