import axios from 'axios'

export default {
    powertripBaseURL: "https://evnav.appstack.me/",
    powertripAPIKey: "U4VQU6Fv0AMaOlvfDNDLnibPn3CEKKZv",
    powertripAccountBaseURL: "https://thepowertrip.co.nz/",//"https://powertrip.littlemonkey.info/",//
    mapAPIKey: "WKMZP4zuyX0nOXlN21X4DLUuezaQY6yv",
    mapURL: "https://maps.littlemonkey.co.nz",
    networks: null,
    plugs: null,
    reverseGeocode(latlng) {
        return axios.get(this.mapURL + "/reverse?lat=" + latlng.lat + "&lon=" + latlng.lng + "&format=json&api_key=" + this.mapAPIKey).then(response => {
            return response.data
        });
    },
    getCampaign() {
        return axios.get(this.powertripAccountBaseURL + "campaign/mine").then(response => {
            return response.data
        }).catch(function () {
            return false
        });
    },
    saveCampaign(data) {
        return axios.post(this.powertripAccountBaseURL + "campaign/mine", data).then(response => {
            return response.data
        })
    },
    getSettings() {
        return axios.get(this.powertripAccountBaseURL + "campaign/settings").then(response => {
            return response.data
        });
    },
    getChargingStations() {
        return axios.get(this.powertripBaseURL + "station/getAllCoordinates?api_key=" + this.powertripAPIKey).then(response => {
            return response.data
        });
    },
    getEVModels() {
        return axios.get(this.powertripBaseURL + "data/evmodels?api_key=" + this.powertripAPIKey).then(response => {
            return response.data
        });
    },
    getTrips(auth) {
        if (auth) {
            return axios.get(this.powertripAccountBaseURL + "station/getTrips", {
                auth: {
                    username: auth.email,
                    password: auth.password
                }
            }).then(response => {
                return response.data
            });
        } else {
            return axios.get(this.powertripBaseURL + "station/getTrips?api_key=" + this.powertripAPIKey).then(response => response);
        }
    },
    sendEmail(data) {
        return axios.post(this.powertripBaseURL + "results?api_key=" + this.powertripAPIKey, data);
    },
    newAccount(data) {
        return axios.post(this.powertripAccountBaseURL + 'register/newuser', data).then(response => {
            return response.data
        }).catch(function (error) {
            return error
        });
    },
    newPoi(data) {
        return axios.post(this.powertripAccountBaseURL + 'station/addPoi',data).then(response => {
            return response.data
        }).catch(function (error) {
            return error
        });
    },
    getPoi(data) {
        return axios.post(this.powertripAccountBaseURL + 'station/getPoi',data).then(response => {
            return response.data
        }).catch(function (error) {
            return error
        });
    },  
    newCharger(data) {
        return axios.post(this.powertripAccountBaseURL + 'station/addCharger',data).then(response => {
            return response.data
        }).catch(function (error) {
            return error
        });
    },
    getChargers(data) {
        return axios.post(this.powertripAccountBaseURL + 'station/getChargers',data).then(response => {
            return response.data
        }).catch(function (error) {
            return error
        });
    },
    getNetworks() {
        if (this.networks) {
            return Promise.resolve(this.networks);
        }
        var $this = this;
        return axios.get(this.powertripAccountBaseURL + "station/getNetworks"). then(response => {
            return $this.networks = response.data
        }).catch(function(error) {
            return error
        });
    },
    getPlugs() {
        if (this.plugs) {
            return Promise.resolve(this.plugs);
        }
        var $this = this;
        return axios.get(this.powertripAccountBaseURL + "station/getPlugs"). then(response => {
            return $this.plugs = response.data
        }).catch(function(error) {
            return error
        });
    },
    login(data) {
        if (data) {
            return axios.get(this.powertripAccountBaseURL + "auth/login", {
                auth: {
                    username: data.email,
                    password: data.password
                }
            }).then(response => {
                return response.data
            }).catch(function (error) {
                return error
            });
        } else {
            return axios.get(this.powertripAccountBaseURL + "auth/login").then(response => {
                return response.data
            }).catch(function (error) {
                return error
            });
        }
    },
    topUp(points) {
        let data = {}
        data.points = points;
        return axios.post(this.powertripAccountBaseURL + "/campaign/buy",data).then(response => {
            return response.data
        }).catch(function(error){
            return error
        });
    },
    saveUserData(data, auth) {
        if (auth) {
        return axios.post(this.powertripAccountBaseURL + "user/updateUser", data, {
            auth: {
                username: auth.email,
                password: auth.password
            }
        }).then(response => {
            return response.data
        }).catch(function (error) {
            return error
        });
    } else {
        return axios.post(this.powertripAccountBaseURL + "user/updateUser", data).then(response => {
            return response.data
        }).catch(function (error) {
            return error
        });
    }
    }


}