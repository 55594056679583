<template lang="pug">
div
  v-autocomplete.mx-4(
    v-model="model.Address",
    :loading="isLoading",
    :items="entries",
    :search-input.sync="search",
    flat,
    item-text="Address",
    hide-details,
    :label="label",
    prepend-icon="mdi-map-search",
    return-object,
    hide-no-data,
    no-filter,
    :clearable="clear",
    attach,
    :auto-select-first="true"
  )
    template(v-slot:item="data")
      v-list-item-content(v-text="data.item.Address")
  #container
</template>

<script>
import StringSimilarity from "string-similarity";
export default {
  name: "AddressFinder",
  props: ["model", "clear", "label"],
  data() {
    return {
      search: null,
      isLoading: false,
      entries: this.model.Address ? [this.model.Address] : [],
      controller: new AbortController(),
    };
  },
  computed: {
    items() {
      var options = this.entries.map((entry) => {
        var address = [];
        if (entry.properties.name !== undefined) {
          address.push(entry.properties.name);
        }
        if (entry.properties.housenumber !== undefined) {
          address.push(entry.properties.housenumber);
        }
        if (entry.properties.street !== undefined) {
          address.push(entry.properties.street);
        }
        if (entry.properties.district !== undefined) {
          address.push(entry.properties.district);
        }
        if (entry.properties.city !== undefined) {
          address.push(entry.properties.city);
        }
        if (address.length > 0 && entry.properties.state !== undefined) {
          address.push(entry.properties.state);
        }
        if (address.length > 0) {
          const Address = address.join(" ");
          return Object.assign({}, entry, { Address });
        }
      });
      options.push(this.model.Address);

      return options;
    },
  },
  methods: {
    filterItems() {
      return true;
    },
    queryAddresses(val) {
      // Items have already been requested

      //  if (this.isLoading) this.controller.abort();

      this.isLoading = true;
      // Lazily load input items
      const signal = this.controller.signal;
      fetch(
        encodeURI(
          "https://maps.littlemonkey.co.nz/complete?limit=150&q=" +
            val +
            "&api_key=utVIXLaVuCG6sXUZJimJ477oCpmmRfkT"
        ),
        { signal }
      )
        .then((res) => res.json())
        .then((res) => {
          const { type, features } = res;
          if (type == "FeatureCollection") {
            let filter_features = features.filter((value) => {
              if (value.properties.type == "region") {
                return false;
              }
              return true;
            });
            let addresses = filter_features.map((entry) => {
              var address = [];
              if (entry.properties.name !== undefined) {
                address.push(entry.properties.name);
              }
              if (entry.properties.housenumber !== undefined) {
                address.push(entry.properties.housenumber);
              }
              if (entry.properties.street !== undefined) {
                address.push(entry.properties.street);
              }
              if (
                address.length > 0 &&
                entry.properties.district !== undefined
              ) {
                address.push(entry.properties.district);
              }
              if (
                address.length > 0 &&
                entry.properties.city !== undefined &&
                entry.properties.city != entry.properties.district
              ) {
                address.push(entry.properties.city);
              }
              if (address.length > 0 && entry.properties.state !== undefined) {
                address.push(entry.properties.state);
              }
              if (address.length > 0) {
                const Address = address.join(" ");
                return Object.assign({}, entry, { Address });
              }
            });
            addresses = addresses.filter((a)=>!!a);

            addresses = addresses.sort((a, b) => {
              let la = StringSimilarity.compareTwoStrings(val, a.Address);
              let lb = StringSimilarity.compareTwoStrings(val, b.Address);
              return lb - la;
            });

            this.entries = addresses;
            if (
              this.model.Address &&
              !this.entries.find(
                (element) => element == this.model.Address.Address
              )
            ) {
              this.entries.push(this.model.Address);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    search(val) {
      val && val !== this.model.Address && this.queryAddresses(val);
    },
    model: {
      handler: function (val) {
        if (val) {
          this.entries.push(val.Address);
          this.entries = this.entries.filter((e)=>!!e);
        }
      },
      deep: true,
    },
  },
};
</script>