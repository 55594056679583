<template lang="pug">
v-container.ma-0.pa-0(fluid, fill-height)
  v-img(height="100%", width="100%")
    l-map(
      v-if="showMap",
      :zoom="zoom",
      :center="center",
      :options="mapOptions",
      style="height: calc(100vh - 154px)",
      @update:center="centerUpdate",
      @update:zoom="zoomUpdate",
      @click="mapclick",
      ref="myMap",
      :maxZoom="19",
      :minZoom="5"
    )
      l-tile-layer(
        :url="url",
        :attribution="attribution",
        :options="layerOptions",
        :tile-layer-class="tileLayerClass"
      )
      l-marker-cluster(:options="{ disableClusteringAtZoom: 9 }")
        l-marker(
          v-for="m in markers",
          :key="m.id",
          :lat-lng="m.LatLng",
          :icon="getIcon(m)"
        )
          l-popup {{ m.name }}
      l-marker-cluster(:options="{ disableClusteringAtZoom: 9 }")
        l-marker(
          v-for="c in chargers",
          :key="c.ID",
          :lat-lng="c.LatLng",
          :icon="getIcon(c)"
        )
          l-popup {{ c.Title }}
      l-circle(
        v-for="m in markers",
        :key="m.id",
        :lat-lng="m.LatLng",
        :radius="parseFloat(m.geoFenceRange)"
      )
      l-marker(:lat-lng="poiLocation", :icon="getIcon(poi)" v-if="poi")
        l-popup {{ poi.Title }}
  v-dialog(v-model="dialog", max-width="360")
    v-card
      v-card-title.headline Location
      v-card-text 
        .text-h6(v-if="address") {{ address.display_name }}
          v-btn.secondary.mt-4(@click="addLocation", block) 
            v-icon mdi-map-marker-plus
            v-spacer
            span Add New Location
            v-spacer
          v-btn.secondary.mt-4(@click="addChargingStation", block) 
            v-icon mdi-ev-station
            v-spacer
            span Add New Charging Station
            v-spacer
          v-btn.secondary.mt-4(@click="moveBusiness",block)
            v-icon mdi-map-marker-circle
            v-spacer
            span Set my business here
            v-spacer
          .text-h4.pa-5.text-center OR
          v-select(
            :items="markers",
            label="Move Location",
            solo,
            item-text="name",
            item-value="id",
            v-model="selected"
          )
          v-select(
            :items="chargers",
            label="Move Charging Station",
            solo,
            item-text="Title",
            item-value="ID",
            v-model="selectedCharger"
          )
        v-progress-linear(v-if="!address", indeterminate) 
      v-card-actions
        v-spacer
        v-btn(text, @click="dialog = false") Cancel
        v-btn.primary(text, @click="confirm") Confirm
</template>

<script>
import MapSupport from "../mixins/MapSupport.js";
import PowerTrip from "../services/api/PowerTrip.js";
import L from "leaflet";
import { mapBus } from "../main";
export default {
  mixins: [MapSupport],
  name: "Map",
  props: ["actionMarkers", "chargerMarkers", "poi"],
  data() {
    return {
      dialog: false,
      clickPoint: null,
      address: null,
      selected: null,
      selectedCharger: null,
     // chargerMarkers: [],
    };
  },
  mounted() {
    this.eventHub.$on("update", (data) => {
      this.chargerMarkers = data;
    });
  },
  computed: {
    poiLocation() {
      return { lat: this.poi.Latitude, lng: this.poi.Longitude };
    },
    markers() {
      let markers = [];
      if (this.actionMarkers) {
        for (var i = 0; i < this.actionMarkers.length; i++) {
          let ch = this.actionMarkers[i];
          if (ch.lat && ch.lng) {
            let m = JSON.parse(JSON.stringify(ch));
            m.LatLng = { lat: m.lat, lng: m.lng };

            markers.push(m);
          }
        }
      }
      return markers;
    },
    chargers() {
      let markers = [];
      if (this.chargerMarkers) {
        for (var i = 0; i < this.chargerMarkers.length; i++) {
          let ch = this.chargerMarkers[i];
          if (ch.Latitude && ch.Longitude) {
            let m = JSON.parse(JSON.stringify(ch));
            m.LatLng = { lat: m.Latitude, lng: m.Longitude };
            markers.push(m);
          }
        }
      }
      return markers;
    },
  },

  methods: {
    moveBusiness() {
      mapBus.$emit("move",this.address);
      this.dialog = false;
    },
    addChargingStation() {
      mapBus.$emit("addCharging", this.address);
      this.dialog = false;
    },
    addLocation() {
      let marker = {};
      marker.lat = this.address.lat;
      marker.lng = this.address.lon;
      marker.address = this.address.display_name;
      let parts = marker.address.split(",");
      if (parts.length > 2) {
        marker.name = parts.slice(0, 2).join(",");
      } else {
        marker.name = marker.address.substring(
          0,
          Math.min(40, marker.address.length)
        );
        if (marker.name.length < marker.address.length) {
          marker.name += "...";
        }
      }

      marker.geoFenceRange = 100;
      mapBus.$emit("add", marker);
      this.dialog = false;
    },
    confirm() {
      this.dialog = false;
      let marker;
      marker = this.actionMarkers.find((el) => el.name == this.selected);
      if (!marker) {
        marker = this.chargerMarkers.find((el) => el.ID == this.selectedCharger);
        marker.Latitude = this.address.lat;
        marker.Longitude = this.address.lon;
        marker.Address = this.address.display_name;
        mapBus.$emit("updateCharger");
      } else {
        marker.lat = this.address.lat;
        marker.lng = this.address.lon;
        marker.address = this.address.display_name;
         mapBus.$emit("updateLocation");
      }
    },
    mapclick(e) {
      this.address = null;
      this.clickPoint = e.latlng;
      PowerTrip.reverseGeocode(this.clickPoint).then((response) => {
        this.address = response;
      });
      this.dialog = true;
    },
    getMarkerBounds() {
      if (this.actionMarkers) {
        let latlngs = [];
        for (var i = 0; i < this.actionMarkers.length; i++) {
          let r = this.actionMarkers[i];
          latlngs.push(L.latLng(r.lat, r.lng));
        }
        return latlngs;
      }
    },
  },
};
</script>
<style scoped>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>