<template lang="pug">
v-expansion-panel.mt-2
  v-expansion-panel-header {{ localCharger.Title || 'New Charger' }}
  v-expansion-panel-content
    v-form
      h4 Charger Image
      v-image-input(
        v-model="image",
        :image-quality="1.0",
        clearable,
        image-format="jpeg",
        :imageWidth="660",
        :imageHeight="250"
        uploadIcon="mdi-camera"
        @input="imageInput"
        overlayPadding="0px"
      )

      v-text-field(label="Charging Station Name", v-model="localCharger.Title" required)
      AddressFinder.mb-5(:model="address", :clear="true", label="Address") 
      v-select(
        :items="operators",
        label="Network or Operator",
        v-model="localCharger.Operator"
      )
      v-select(
        :items="connectors",
        label="Charging Connections",
        multiple,
        v-model="localCharger.Plugs"
      )
      v-row
        v-col(cols=6)
          v-text-field(
            suffix="kW",
            label="Power",
            type="number",
            v-model="localCharger.KwRated"
          )
        v-col(cols=6)
          v-select(
            :items="currents",
            label="Current",
            v-model="localCharger.Current"
          )
      v-text-field(
        type="number",
        prefix="$",
        label="Cost per kWh",
        v-model="localCharger.KwhCost"
      )
      v-row
        v-col(cols=6)
          v-select(
            :items="durations",
            label="Cost per",
            v-model="localCharger.TimeCostPeriod"
          )
        v-col(cols=6)
          v-text-field(
            type="number",
            prefix="$",
            label="",
            v-model="localCharger.TimeCost"
          )
      v-row
        v-col(cols="1")
          v-icon mdi-phone
        v-col(cols="11")
          vue-phone-number-input(
            clearable,
            prepend-icon="mdi-email",
            v-model="localCharger.Phone",
            :translations="translations"
          )
      v-textarea(
        label="More information and instructions",
        v-model="localCharger.Description"
      )
</template>

<script>
import AddressFinder from "./AddressFinder.vue";
import ImageInput from "./ImageInput.vue";
import PowerTrip from "../services/api/PowerTrip.js";
import { bus, mapBus } from "../main";
import VImageInput from "vuetify-image-input/a-la-carte";
import _ from "lodash";
export default {
  props: ["location", "charger"],
  components: { AddressFinder, ImageInput, VImageInput },
  data() {
    return {
      address: { Address: "" },
      localCharger: null,
      oldCharger: null,
      image: null,
      saveImage: null,
      translations: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Country error",
        phoneNumberLabel: "Emergency contact number",
        example: "Example:",
      },
      operators: [
        "Vector Energy",
        "Contact",
        "Counties",
        "Power",
        "Alpine",
        "Energy",
        "Central Lines",
        "Network Tasman",
        "Orion",
        "Unison",
        "Horizon",
        "ChargeNet",
        "Unknown",
        "I own this",
      ],
      connectors: [
        "CHAdeMO",
        "Type 2 CCS",
        "Type 2 Socketed",
        "Type 2 Tethered",
        "Type 1 Tethered",
        "Tesla Supercharger",
        "Blue Commando",
        "Type 1 - Socketed",
        "Wall Plug (NZ & Australia)",
      ],
      currents: ["AC", "DC"],
      durations: ["Minute", "Hour", "Day", "Session"],
    };
  },
  created() {
    this.setupCharger();
    this.oldCharger = JSON.parse(JSON.stringify(this.localCharger));
    PowerTrip.getNetworks().then((n) => (this.operators = n));
    PowerTrip.getPlugs().then((p) => (this.connectors = p));
    bus.$on("save", () => {
      this.saveCharger();
    });
    bus.$on("undo", () => {
      this.undo();
    });
    mapBus.$on("updateCharger",() => {
      this.setupCharger()
    });
  },
  methods: {
    setupCharger() {
      this.localCharger = this.charger;
      this.address = this.convertAddress(this.charger);
      this.image = this.charger.PhotoData;
    },
    imageInput() {
      this.saveImage = true;
    },
    saveCharger() {
      if (this.saveImage) {
        this.localCharger.PhotoData = this.image;
      }
  
      PowerTrip.newCharger(this.localCharger);
      this.oldCharger = JSON.parse(JSON.stringify(this.localCharger));
    },
    undo() {
      this.localCharger = JSON.parse(JSON.stringify(this.oldCharger));
    },
    convertAddress(data) {
      var address = { Address: "" };
      if (data.Address) {
        let addressString = data.Address;//typeof data.Address === "string" ? data.Address : data.Address.Address;
        address.Address = (addressString.length > 0) ? addressString : "";
        address.geometry = {};
        address.geometry.coordinates = [];
        address.geometry.coordinates.push(data.Longitude);
        address.geometry.coordinates.push(data.Latitude);
      } else {
        data.Address = { Address: "" };
      }
      return address;
    },
  },
  watch: {
    address: {
      deep: true,
      handler() {
        this.localCharger.Address = typeof this.address.Address === "string" ? this.address.Address : this.address.Address.Address;
        if (this.address.Address.geometry) {
        this.localCharger.Latitude =
          this.address.Address.geometry.coordinates[1];
        this.localCharger.Longitude =
          this.address.Address.geometry.coordinates[0];
        }
      },
    },
    localCharger: {
      handler() {
        if (!_.isEqual(this.localCharger, this.oldCharger)) {
          bus.$emit("dirty");
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
@import "../../node_modules/vue-phone-number-input/dist/vue-phone-number-input.css";
</style>