<template lang="pug">
v-row 
  v-col(cols=12, lg=5)
    CampaignCard(
      v-if="campaign",
      :campaign.sync="campaign",
      :user="user",
      @userUpdated="updateUser",
      @campaignUpdated="campaignUpdated",
      @undo="undo"
      :chargers="chargers"
    )
  v-col(cols=12, lg=7)
    Map(:actionMarkers="campaign ? campaign.locations : null" :chargerMarkers="chargers" :poi="this.poi")
</template>

<script>
import Map from "../components/Map";
import CampaignCard from "../components/CampaignCard";
import PowerTrip from "../services/api/PowerTrip";
import axios from "axios";
import Vue from "vue";
import {bus} from "../main";
export default {
  name: "Home",
  data() {
    return {
      campaign: null,
      user: this.$user,
      chargers: [],
      poi: null
    };
  },
  components: {
    Map,
    CampaignCard,
  },
  created() {
        PowerTrip.getPoi().then((r) => {
      this.poi = r;
        });
    bus.$on("poi",(p)=>this.poi = p);
  },
  methods: {
    async updateUser() {
      console.log("Update User");
      Vue.prototype.$user = await PowerTrip.login();
      localStorage.setItem("token", this.$user.token);
      axios.defaults.headers.common["X-Auth-Powertrip"] = this.$user.token;
      this.user = this.$user;
    },
    async campaignUpdated() {
      this.campaign = await PowerTrip.getCampaign();
    },
    undo(old) {
      this.campaign = JSON.parse(JSON.stringify(old));
    },
  },
  async mounted() {
    this.campaign = await PowerTrip.getCampaign();
    if (!this.campaign) {
      this.$router.push("/login");
    }
  },
};
</script>
