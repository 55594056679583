import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import '../node_modules/leaflet/dist/leaflet.css';
import axios from 'axios';
import VStripeElements from 'v-stripe-elements/lib';
import VuePhoneNumberInput from 'vue-phone-number-input';
import LoadScript from 'vue-plugin-load-script'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'  //Globally import VTextField

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
	locale: 'en',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: false
})
 
const eventHub = new Vue() // Single event hub

// Distribute to components using global mixin
Vue.mixin({
	data: function () {
		return {
			eventHub: eventHub
		}
	}
})

Vue.use(LoadScript)
axios.defaults.headers.common['X-Version-Powertrip'] = "2.0";
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(VStripeElements);
Vue.config.productionTip = false

let token = localStorage.getItem("token");
if (token) {
axios.defaults.headers.common["X-Auth-Powertrip"] = token;
}
export const mapBus = new Vue();
export const bus = new Vue();
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
