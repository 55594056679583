<template lang="pug">
  v-app
    v-app-bar(app color="primary")
      v-icon mdi-lightning-bolt-outline
      v-toolbar-title Lightning Points Campaign Manager
      v-spacer
      v-switch.mt-5(v-model="$vuetify.theme.dark" small append-icon='mdi-theme-light-dark' color="white")
      v-btn(icon @click="logout")
        v-icon mdi-logout
        

    v-main(app fill-height)
      router-view
    v-footer(padless)
      v-col(cols=1)
      v-col.text-center(cols=10) {{ new Date().getFullYear() }} — <strong>Power Trip</strong>
      v-col(cols=1)

</template>

<script>
import axios from 'axios';
export default {
  name: 'App',

  data: () => ({

  }),

  methods: {
    logout() {
      localStorage.removeItem("token");
       axios.defaults.headers.common["X-Auth-Powertrip"] = "";
       this.$router.go();
    }
  }
};
</script>
