<template lang="pug">
v-card.ma-0(tile)
    v-card-title {{ hour.dayOfWeek }}
    v-card-text
        v-row.ma-0.pa-0
            v-col.pa-0
                v-checkbox(v-if="!hour.closed" label="24 hours" v-model="hour.open24")
            v-col.pa-0
                v-checkbox(v-if="!hour.open24" label="Closed" v-model="hour.closed")
        v-row.ma-0.pa-0
            v-col.pa-0
                v-dialog(v-if="!hour.open24 && !hour.closed" v-model="openModal" max-width="320" ref="openDialog")
                    template(v-slot:activator="{ on, attrs }")
                        v-text-field(
                        v-model="hour.openingTime",
                        prepend-icon="mdi-clock-time-four-outline",
                        readonly,
                        v-bind="attrs",
                        v-on="on"
                        label="Opening Time"
                        )
                    v-time-picker(v-if="openModal" label="Opening Time", v-model="hour.openingTime" ampm-in-title full-width)
                        v-spacer
                        v-btn(text @click="closeModal = false") Cancel
                        v-btn.primary(@click="$refs.openDialog.save(hour.closingTime)") OK
            v-col.pa-0
                v-dialog(v-if="!hour.open24 && !hour.closed" v-model="closeModal" max-width="320" ref="closeDialog")
                    template(v-slot:activator="{ on, attrs }")
                        v-text-field(
                        v-model="hour.closingTime",
                        prepend-icon="mdi-clock-time-four-outline",
                        readonly,
                        v-bind="attrs",
                        v-on="on"
                        label="Closing Time"
                        )
                    v-time-picker(v-if="closeModal" label="Closing Time", v-model="hour.closingTime" ampm-in-title full-width)
                        v-spacer
                        v-btn(text @click="closeModal = false") Cancel
                        v-btn.primary(@click="$refs.closeDialog.save(hour.closingTime)") OK

</template>
<script>
export default {
  props: ["hour"],
  data() {
    return {
      openModal: false,
      closeModal: false,
    };
  },
};
</script>