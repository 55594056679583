import L from "leaflet";
import ChargerPopup from "../components/ChargerPopup.vue";
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  LCircle
} from "vue2-leaflet";
import LProtobuf from 'vue2-leaflet-vectorgrid';
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import vectorStyle from '../data/style';
import mapboxgl from 'mapbox-gl';
import brightStyle from '../data/style.json';
import 'mapbox-gl-leaflet';
import 'mapbox-gl/dist/mapbox-gl.css';
window.maboxgl = mapboxgl;
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
export default {
  data() {
    return {
      zoom: 5.5,
      center: L.latLng(-40, 174),
      attribution: "<a href='http://openmaptiles.org/' rel='nofollow'>© OpenMapTiles</a> | <a href='http://www.openstreetmap.org/copyright' rel='nofollow'>© OpenStreetMap contributors</a>",
      url: "",
      showMap: true,
      currentZoom: 5.5,
      currentCenter: L.latLng(-40, 174),
      mapOptions: {
        zoomSnap: 0.5
      },
      tileLayerClass: (url, options) => L.mapboxGL(options),
      layerOptions: {
        style: brightStyle
      },
      options: {
        attribution: '© ESRI',
        vectorTileLayerStyles: vectorStyle
      },
      ac_icon_1: L.icon({
        iconUrl: "https://powersell.appstack.me/icons/ACCharger1.svg",
        iconSize: [50, 50],
        iconAnchor: [25, 50],
        popupAnchor: [0, -40]
      }),
      dc_icon_1: L.icon({
        iconUrl: "https://powersell.appstack.me/icons/DCCharger1.svg",
        iconSize: [50, 50],
        iconAnchor: [25, 50],
        popupAnchor: [0, -40]
      }),
      ac_icon_2: L.icon({
        iconUrl: "https://powersell.appstack.me/icons/ACCharger2.svg",
        iconSize: [50, 50],
        iconAnchor: [25, 50],
        popupAnchor: [0, -40]
      }),
      dc_icon_2: L.icon({
        iconUrl: "https://powersell.appstack.me/icons/DCCharger2.svg",
        iconSize: [50, 50],
        iconAnchor: [25, 50],
        popupAnchor: [0, -40]
      }),
      ac_icon_3: L.icon({
        iconUrl: "https://powersell.appstack.me/icons/ACCharger3.svg",
        iconSize: [50, 50],
        iconAnchor: [25, 50],
        popupAnchor: [0, -40]
      }),
      dc_icon_3: L.icon({
        iconUrl: "https://powersell.appstack.me/icons/DCCharger3.svg",
        iconSize: [50, 50],
        iconAnchor: [25, 50],
        popupAnchor: [0, -40]
      }),
      place_marker: L.icon({
        iconUrl: "https://powersell.appstack.me/icons/LightningPoint.svg",
        iconSize: [50, 50],
        iconAnchor: [25, 50],
        popupAnchor: [0, -40]
      }),
      poi_marker: L.icon({
        iconUrl: "https://powersell.appstack.me/icons/Place.svg",
        iconSize: [50, 50],
        iconAnchor: [25, 50],
        popupAnchor: [0, -40]
      })
    };
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    LCircle,
    "l-marker-cluster": Vue2LeafletMarkerCluster,
    ChargerPopup,
    LProtobuf
  },
  methods: {
    getIcon(m) {
      if (!m.Current) {
        if (m.Latitude) {
          return this.poi_marker;
        } else {
          return this.place_marker;
        }
      }
      if (m.Current == "AC") {
        if (m.KwRated < 40) {
          return this.ac_icon_1;
        }
        if (m.KwRated < 140) {
          return this.ac_icon_2;
        }
        return this.ac_icon_3;
      }
      if (m.KwRated < 40) {
        return this.dc_icon_1;
      }
      if (m.KwRated < 140) {
        return this.dc_icon_2;
      }
      return this.dc_icon_3;

    },
    getChargerByID(id) {
      if (this.chargers != null) {
        for (let c of this.chargers) {
          if (c.ID == id) {
            return c;
          }
        }
      }
      return null;
    },
    mapclick(location) {
      this.$emit("clickedLocation", location);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    innerClick() {
      alert("Click!");
    },
    createRing(center, radius, innerRadius) {
      var points = 180;
      var radiusLatitude = this.toDegrees(radius / this.EARTH_RADIUS);
      var radiusLongitude =
        radiusLatitude / Math.cos(this.toRadians(center.lat));
      var shape = [];
      var anglePerCircleRegion = (2 * Math.PI) / points;
      for (let i = 0; i <= points; i++) {
        let theta = i * anglePerCircleRegion;
        let latitude = center.lat + radiusLatitude * Math.sin(theta);
        let longitude = center.lng + radiusLongitude * Math.cos(theta);
        shape.push([latitude, longitude]);
      }

      radiusLatitude = this.toDegrees(innerRadius / this.EARTH_RADIUS);
      radiusLongitude = radiusLatitude / Math.cos(this.toRadians(center.lat));
      var hole = [];
      for (let i = 0; i <= points; i++) {
        let theta = i * anglePerCircleRegion;
        let latitude = center.lat + radiusLatitude * Math.sin(theta);
        let longitude = center.lng + radiusLongitude * Math.cos(theta);
        hole.push([latitude, longitude]);
      }

      return [shape, hole];
    }
  }
}
