/**
 * Vector styling for the public ESRI Vector tiles endpoint.
 * This style is copied from the Leaflet.VectorGrid library example here:
 * https://github.com/Leaflet/Leaflet.VectorGrid/blob/master/docs/demo-vectortiles.html
 */


var vectorTileStyling = {
	water: {
		fill: true,
		weight: 1,
		fillColor: '#2375e0',
		color: '#2375e0',
		fillOpacity: 0.2,
		opacity: 0.4,
	},
	admin: {
		weight: 1,
		fillColor: 'pink',
		color: 'pink',
		fillOpacity: 0.2,
		opacity: 0.4
	},
	waterway: {
		weight: 1,
		fillColor: '#2375e0',
		color: '#2375e0',
		fillOpacity: 0.2,
		opacity: 0.4
	},
	landcover: {
		fill: true,
		weight: 1,
		fillColor: '#53e033',
		color: '#53e033',
		fillOpacity: 0.2,
		opacity: 0.4,
	},
	landuse: {
		fill: true,
		weight: 1,
		fillColor: '#e5b404',
		color: '#e5b404',
		fillOpacity: 0.2,
		opacity: 0.4
	},
	park: {
		fill: true,
		weight: 1,
		fillColor: '#84ea5b',
		color: '#84ea5b',
		fillOpacity: 0.2,
		opacity: 0.4
	},
	boundary: {
		weight: 1,
		fillColor: '#c545d3',
		color: '#c545d3',
		fillOpacity: 0.2,
		opacity: 0.4
	},
	aeroway: {
		weight: 1,
		fillColor: '#51aeb5',
		color: '#51aeb5',
		fillOpacity: 0.2,
		opacity: 0.4
	},
	road: {	// mapbox & mapzen only
		weight: 1,
		fillColor: '#f2b648',
		color: '#f2b648',
		fillOpacity: 0.2,
		opacity: 0.4
	},
	tunnel: {	// mapbox only
		weight: 0.5,
		fillColor: '#f2b648',
		color: '#f2b648',
		fillOpacity: 0.2,
		opacity: 0.4,
// 					dashArray: [4, 4]
	},
	bridge: {	// mapbox only
		weight: 0.5,
		fillColor: '#f2b648',
		color: '#f2b648',
		fillOpacity: 0.2,
		opacity: 0.4,
// 					dashArray: [4, 4]
	},
	transportation: {	// openmaptiles only
		weight: 0.5,
		fillColor: '#f2b648',
		color: '#f2b648',
		fillOpacity: 0.2,
		opacity: 0.4,
// 					dashArray: [4, 4]
	},
	transit: {	// mapzen only
		weight: 0.5,
		fillColor: '#f2b648',
		color: '#f2b648',
		fillOpacity: 0.2,
		opacity: 0.4,
// 					dashArray: [4, 4]
	},
	building: {
		fill: true,
		weight: 1,
		fillColor: '#2b2b2b',
		color: '#2b2b2b',
		fillOpacity: 0.2,
		opacity: 0.4
	},
	water_name: {
		weight: 1,
		fillColor: '#022c5b',
		color: '#022c5b',
		fillOpacity: 0.2,
		opacity: 0.4
	},
	transportation_name: {
		weight: 1,
		fillColor: '#bc6b38',
		color: '#bc6b38',
		fillOpacity: 0.2,
		opacity: 0.4
	},
	place: {
		weight: 1,
		fillColor: '#f20e93',
		color: '#f20e93',
		fillOpacity: 0.2,
		opacity: 0.4
	},
	housenumber: {
		weight: 1,
		fillColor: '#ef4c8b',
		color: '#ef4c8b',
		fillOpacity: 0.2,
		opacity: 0.4
	},
	poi: {
		weight: 1,
		fillColor: '#3bb50a',
		color: '#3bb50a',
		fillOpacity: 0.2,
		opacity: 0.4
	},
	earth: {	// mapzen only
		fill: true,
		weight: 1,
		fillColor: '#c0c0c0',
		color: '#c0c0c0',
		fillOpacity: 0.2,
		opacity: 0.4
	},


	// Do not symbolize some stuff for mapbox
	country_label: [],
	marine_label: [],
	state_label: [],
	place_label: [],
	waterway_label: [],
	poi_label: [],
	road_label: [],
	housenum_label: [],


	// Do not symbolize some stuff for openmaptiles
	country_name: [],
	marine_name: [],
	state_name: [],
	place_name: [],
	waterway_name: [],
	poi_name: [],
	road_name: [],
	housenum_name: []
}
var tilestyle = {};
tilestyle.place=[];
tilestyle.place_detail=[];
tilestyle.poi=[];
tilestyle.poi_detail=[];
tilestyle.housenumber=[];
tilestyle.waterway=[];//vectorTileStyling.waterway;
tilestyle.transportation=vectorTileStyling.road;
tilestyle.transportation_mid=vectorTileStyling.road;        
tilestyle.transportation_detail=vectorTileStyling.road;     
tilestyle.transportation_name=[];//vectorTileStyling.transportation_name;       
tilestyle.transportation_name_detail=[];//vectorTileStyling.transportation_name;
tilestyle.building=[];
tilestyle.water=vectorTileStyling.water;
tilestyle.water_name=[];//vectorTileStyling.water_name;
tilestyle.water_name_detail=[];//vectorTileStyling.water_name;
tilestyle.aeroway=vectorTileStyling.aeroway;
tilestyle.park=vectorTileStyling.park;
tilestyle.landuse=vectorTileStyling.landuse;
tilestyle.landcover=vectorTileStyling.landcover;
tilestyle.mountain_peak=[];

export default tilestyle;